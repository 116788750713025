import React from "react";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button,  CardActions } from '@mui/material';
import Box from '@mui/material/Box';

function Header() {
    return (
        <React.Fragment>
    
        
       
            

        <Box sx={{ width: '100%', maxWidth: 1500 }}>
       
      <CardContent> 

      <Typography variant="h4" color="text.secondary">

      To see my complete list of publication, please click below

      </Typography>

      </CardContent>  
		  <CardActions>
		
    <Button size="large" variant="contained" color="primary" href= "https://scholar.google.com/citations?user=mk7KlSMAAAAJ&hl=en">
      Read it here
    </Button>
   
     </CardActions>
    






       
        </Box> 
        
        

 </React.Fragment>

 );
}


export default Header;
