import React from 'react';
import ReactDOM from 'react-dom/client';
import theme from '/home/sd/Desktop/soupage/souweb/src/theme.jsx';
import Blog from '/home/sd/Desktop/soupage/souweb/src/Blog.jsx';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ThemeProvider theme={theme}>
     <CssBaseline />
    <Blog />
    </ThemeProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
