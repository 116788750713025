import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';






function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
     &emsp; {'Copyright © '}
     Created by Sandipan Datta
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function StickyFooter() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
    
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="fixed">
            <Typography variant="body1">
         
            <Stack direction="column" justifyContent="space-evenly"
              alignItems="flex-start" spacing={1}> 
            
            <Button href="/Research">Research</Button>
            <Button href="/People">People</Button>
            <Button href="/Publications">Publications</Button>
            <Button href="/Positions">Positions</Button>
            

            
           
          &emsp;Contact Me
           

           Dr. Sourav Chatterjee

           Lecturer (Assistant Professor)
           Dept. of Chemical Engineering
           University of Bath｜Bath | BA2 7AY | UK

           Office: 9W 2.08
     
      </Stack>
     
            </Typography>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}