import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Souprof from '/home/sd/Desktop/soupage/souweb/src/images/SouravProf.jpg'
import mi from '/home/sd/Desktop/soupage/souweb/src/images/MyImg.jpg'




export default function Profile() {
  return (

  

  
    <Box sx={{ width: '100%', maxWidth: 3400 }}>
	<Card sx={{ maxWidth: 1400 }}>
  <Stack spacing={2}>

  <Card sx={{ maxWidth: 1400 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="520"
          image={Souprof}
          alt="SouravProf"
        /> 
		
		 <CardContent>
          <Typography gutterBottom variant="h5" component="div">
		  Dr. Sourav Chatterjee
          </Typography>
          <Typography variant="body1" color="text.secondary">
          I am lecturer (Assistant professor) in the department of Chemical Engineering at 
          University of Bath and the Principal Investigator of AI/ML guided Automated Flow Synthesis Laboratory. 
		      My research encompasses flow chemistry, microfluidics, machine learning and software engineering.
		      I have worked on research projects including unsteady state multiphase micro-reactor design for 
          API synthesis, designed automated flow chemical synthesizer machines for multistep organic synthesis 
		      and used machine learning for solving complex chemical problems. 
          </Typography>
		  
        </CardContent>
      </CardActionArea>
      <CardActions>
		
        <Button size="large" variant="contained" color="primary" href= "https://scholar.google.com/citations?user=mk7KlSMAAAAJ&hl=en&oi=sra">
          My Google Scholar
        </Button>

        <Button size="large" variant="contained" color="primary" href= "https://researchportal.bath.ac.uk/en/persons/sourav-chatterjee">
          My University of Bath Website
        </Button>
      </CardActions>
    </Card> 
  
   
    <Typography gutterBottom variant="h5" component="div">
		       Undergraduates
          </Typography>
          <Typography variant="body1" color="text.secondary">
		        Will be Updated (Februrary 2024)
          </Typography> 

          <Typography gutterBottom variant="h5" component="div">
		      PhD Students 
          </Typography>
          <Typography variant="body1" color="text.secondary" >
		       
          Apply in the group&emsp;
          <Button size="small" variant="contained" color="primary" href= "https://www.findaphd.com/phds/project/university-of-bath-ursa-project-automated-synthesis-and-artificial-intelligence-guided-discovery-of-heterogenous-catalysts-for-co-oxidation/?p162956">
          Apply
        </Button>

         
          </Typography>

          

          <Typography gutterBottom variant="h5" component="div">
		      Permanent Members 
          </Typography>
          <div classname="container Img__container1">
                <img src={mi} width={86} height={107} alt="Uni Logo" />
                 </div>
                 
       <Typography variant="h6" color="text.secondary">
            Sandipan Datta - Research Engineer and Software Developer
          </Typography>
          
          <Typography variant="body1" color="text.secondary">
            Sandipan is a research chemical engineer, IT manager and software developer in the group. He has extensive
            experience in Labview, Matlab, Python and C programming. He is also a full stack web developer and frequently
            uses MERN software stack. He is also involved in Automation and Machine learning researh projects in the group. 
            </Typography>
            <Typography variant="body1" color="text.secondary">
                 <Button size="small" variant="contained" color="primary" href= "https://in.linkedin.com/in/sandipan-datta-7642821b">
            LinkedIn
          </Button>
          </Typography>
		  
		  
		      
		 </Stack> 
      
    </Card>
      
    </Box>
    
  );
}

  
      
    
  



